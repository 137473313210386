import consumer from "./consumer"


$(document).ready(function() {

  const element = document.getElementById('room-jefe-jueces-id');
  const room_id = element ? element.getAttribute('data-jefe-jueces-id') : '';



  if (room_id != '' ){
    consumer.subscriptions.create({channel: "JefejuecesChannel", room:room_id},{ 
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Conectado al Jefejueces channel: " + room_id)
        
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Desconetado del channel de Jefejueces...")
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        
        //var iframe = document.getElementById('iframe_jefejueces');
        //iframe.src = iframe.src; // Recarga el iframe estableciendo su src al mismo valor
        //console.log("solicitud de recargar el iframe jefejueces...")

        $("#btn_actualizar_pagina").click()

      }
    });

  }
    
});

