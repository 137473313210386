import consumer from "./consumer"


$(document).ready(function() {

  const element = document.getElementById('room-id');
  const room_id = element ? element.getAttribute('data-room-id') : '';

  console.log('room_id: ' + room_id);

  function createSquare(p,color) {
      // Crear el div con clase "square enlinea red"
      var square = document.createElement('div');

      
      //Si hay prioridad suspendida agregar clase para que parpadee
      if (p.endsWith(":S")) {
        square.className = 'square_s parpadeo enlinea '+ color;
      } else {
        square.className = 'square enlinea '+ color;
      }      

      // Crear el div interno con estilo de margen y texto "P1"
      var innerDiv = document.createElement('div');
      innerDiv.style.marginTop = '0.15rem';
      innerDiv.textContent = p;

      // Añadir el div interno al div "square"
      square.appendChild(innerDiv);

      // Añadir el div "square" al contenedor principal
      document.getElementById('detalle_prioridad').appendChild(square);
  };


  if (room_id != '' ){

    consumer.subscriptions.create({channel: "PrioridadChannel", room:room_id},{ 
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Conectado al prioridad channel..." + room_id)
        
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var datajson = JSON.parse(data);
        var detallePrioridad = document.getElementById('detalle_prioridad');
        var fondo_prioridad = document.getElementById('fondo_prioridad');

        //Borrar las proiridades anteriores
        while (detallePrioridad.firstChild) {
            detallePrioridad.removeChild(detallePrioridad.firstChild);
        }

        //Crear los div con las nuevas prioridades
        var cont = 0      
        for (var key in datajson) {
          cont = cont + 1 ;
          createSquare("P"+key, datajson[key]);
          
        }

        //ajustar el ancho del fondo gris al número de prioridades
        if (cont >0 ){
          $("#con_sin_prioridades").removeClass("ocultar");
          var wdt = cont*37
          fondo_prioridad.style.width = wdt+'px';
        }
        else {
          $("#con_sin_prioridades").addClass("ocultar");
        }

      }
    });

  }


    
});

